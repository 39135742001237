import React from 'react'

import { Container, List, Item, Icon, Text } from './styles'

import list from './list'

const WhatWeDo = () => {
  return (
    <Container id="what-we-do-section">
      <h1>O que nós fazemos?</h1>

      <List>
        {list.map((item, index) => {
          return (
            <Item key={`item-${index}`}>
              <Icon src={item.icon} />
              <Text>{item.text}</Text>
            </Item>
          )
        })}
      </List>
    </Container>
  )
}

export default WhatWeDo
