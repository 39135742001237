import React from 'react'

import { Container } from './styles'

const CityList = () => {
  function importAll(r) {
    return r.keys().map(r)
  }

  const images = importAll(
    require.context('./CitiesName', false, /\.(png|jpe?g|svg)$/)
  )

  return (
    <Container>
      <ul>
        {images.map((path, index) => (
          <li key={index}>
            <img src={path} alt="Brasão" />
          </li>
        ))}
      </ul>
    </Container>
  )
}

export default CityList
