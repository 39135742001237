import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 300px;
  background-color: #fff;
  box-shadow: 0 0 28px rgba(0, 0, 0, 0.3);

  ul {
    padding: 0 80px;
    display: flex;
    align-items: center;

    animation: slide-left 160s linear infinite both;

    @keyframes slide-left {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-100%);
      }
    }

    li img {
      height: 100px;
      margin-right: 130px;
      transition: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) all;

      &:hover {
        height: 130px;
      }
    }
  }
`
