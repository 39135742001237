import styled from 'styled-components'

export const Container = styled.div`
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 720px;
  overflow-y: hidden;
  z-index: 2;
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;

  @media (min-width: 1024px) {
    height: 900px;
  }

  @media (min-width: 1440px) {
    height: 1050px;
  }

  div.info {
    max-width: 500px;
    padding: 0 30px;

    h1,
    h2,
    p {
      color: #fff;

      @media (max-width: 768px) {
        text-align: center;
      }
    }

    h1 {
      font-family: 'Gilroy-Bold';
      margin-bottom: 5px;
    }

    h2 {
      letter-spacing: 1px;
      font-family: 'Gilroy-Thin';
      margin-bottom: 15px;
    }

    @media (min-width: 768px) {
      padding-left: var(--padding-horizontal);
      max-width: 700px;
    }
  }

  @media (max-width: 768px) {
    align-items: center;
  }
`
