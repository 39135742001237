import styled from 'styled-components'

import backgroundMobile from 'src/assets/img/Local/BackgroundMobile.webp'
import background from 'src/assets/img/Local/Background.webp'
import backgroundWide from 'src/assets/img/Local/BackgroundWide.webp'

export const Container = styled.div`
  z-index: 4;
  position: relative;
`

export const ImgContainer = styled.div`
  background-image: url(${background});
  background-size: cover;
  min-height: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (max-width: 790px) {
    align-items: flex-start;
    padding: 45px 30px 0 30px;
    background-image: url(${backgroundMobile});
    min-height: 1000px;
  }

  @media (max-width: 524px) {
    min-height: 1200px;
  }

  @media (min-width: 1920px) {
    background-image: url(${backgroundWide});
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    left: var(--padding-horizontal);

    @media (max-width: 790px) {
      left: 0px;
      bottom: 30px;
      width: 100%;
      align-items: center;
    }

    .list {
      display: flex;
      flex-direction: column;

      @media (max-width: 790px) {
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
        width: 90%;
      }
    }

    .description {
      width: 350px;
      color: #fff;
      border-left: 6px solid #fff;
      padding-left: 10px;
      margin-bottom: 40px;
      font-size: 22px;

      @media (max-width: 430px) {
        width: 280px;
      }

      @media (max-width: 768px) {
        border-left: none;
        border-top: 4px solid #fff;
        text-align: center;
        padding-top: 25px;
      }
    }
  }

  img {
    width: 600px;
    margin-left: 350px;
    transition: 0.3s all ease;

    @media (min-width: 790px) {
      width: 400px;
      margin-left: 350px;
    }

    @media (max-width: 790px) {
      width: 300px;
      margin: 0;
    }

    @media (min-width: 1024px) {
      width: 600px;
      margin-left: 350px;
    }
  }
`
