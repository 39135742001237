import React from 'react'
import { useMediaQuery } from 'react-responsive'

const SvgCurve = ({ top = false }) => {
  const phone = useMediaQuery({ query: '(max-width: 425px)' })
  const tablet = useMediaQuery({ query: '(max-width: 768px)' })
  const desktop = useMediaQuery({ query: '(max-width: 1024px)' })
  const desktopLarge = useMediaQuery({ query: '(max-width: 1920px)' })
  const desktop4k = useMediaQuery({ query: '(min-width: 1921px)' })

  if (phone) {
    if (top) {
      return (
        <svg
          width="426"
          height="149"
          viewBox="0 0 426 149"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1 0H426V73.6753C238 73.6753 96.5 48.1363 1 5.60506V0Z"
            fill="#1B6BAD"
          />
          <path
            d="M1 79.9297C96.5 122.461 238 148 426 148"
            stroke="white"
            stroke-width="2"
          />
        </svg>
      )
    } else {
      return (
        <svg
          width="426"
          height="149"
          viewBox="0 0 426 149"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M425 149H0V75.3247C188 75.3247 329.5 100.864 425 143.395V149Z"
            fill="#1B6BAD"
          />
          <path
            d="M425 69.0703C329.5 26.5391 188 1.0001 0 1.0001"
            stroke="white"
            stroke-width="2"
          />
        </svg>
      )
    }
  }

  if (tablet) {
    if (top) {
      return (
        <svg
          width="770"
          height="226"
          viewBox="0 0 770 226"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1 0H769V108.833C589.368 167.06 178 73 1 0Z"
            fill="#1B6BAD"
          />
          <path
            d="M1 97C178 170 589.368 264.06 769 205.833"
            stroke="white"
            stroke-width="3"
          />
        </svg>
      )
    } else {
      return (
        <svg
          width="770"
          height="226"
          viewBox="0 0 770 226"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M769 225.998H1V117.165C180.632 58.9377 592 152.998 769 225.998Z"
            fill="#1B6BAD"
          />
          <path
            d="M769 128.998C592 55.998 180.632 -38.0623 1 20.1646"
            stroke="white"
            stroke-width="3"
          />
        </svg>
      )
    }
  }

  if (desktop) {
    if (top) {
      return (
        <svg
          width="1026"
          height="226"
          viewBox="0 0 1026 226"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1 0H1025V108.833C785.49 167.06 237 73 1 0Z"
            fill="#1B6BAD"
          />
          <path
            d="M1 97C237 170 785.49 264.06 1025 205.833"
            stroke="white"
            stroke-width="4"
          />
        </svg>
      )
    } else {
      return (
        <svg
          width="1026"
          height="226"
          viewBox="0 0 1026 226"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1025 225.998H1V117.165C240.51 58.9377 789 152.998 1025 225.998Z"
            fill="#1B6BAD"
          />
          <path
            d="M1025 128.998C789 55.998 240.51 -38.0623 1 20.1646"
            stroke="white"
            stroke-width="4"
          />
        </svg>
      )
    }
  }

  if (desktopLarge) {
    if (top) {
      return (
        <svg
          width="1922"
          height="309"
          viewBox="0 0 1922 309"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1 0H1921V171.394C1471.92 263.091 443.5 114.963 1 0Z"
            fill="#1B6BAD"
          />
          <path
            d="M1 107C443.5 221.963 1471.92 370.092 1921 278.394"
            stroke="white"
            stroke-width="4"
          />
        </svg>
      )
    } else {
      return (
        <svg
          width="1922"
          height="309"
          viewBox="0 0 1922 309"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1921 309H1V137.606C450.081 45.9085 1478.5 194.037 1921 309Z"
            fill="#1B6BAD"
          />
          <path
            d="M1921 202C1478.5 87.0375 450.081 -61.0915 1 30.6059"
            stroke="white"
            stroke-width="4"
          />
        </svg>
      )
    }
  }

  if (desktop4k) {
    if (top) {
      return (
        <svg
          width="2562"
          height="428"
          viewBox="0 0 2562 428"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1 0H2561V271.66C1962.23 417 591 182.216 1 0Z"
            fill="#1B6BAD"
          />
          <path
            d="M1 108C591 290.216 1962.23 525 2561 379.66"
            stroke="white"
            stroke-width="5"
          />
        </svg>
      )
    } else {
      return (
        <svg
          width="2563"
          height="326"
          viewBox="0 0 2563 326"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M793.781 120.436C1288.99 76.3978 2057.11 176.821 2562 326H2C174.345 201.765 582.546 125.458 793.781 120.436Z"
            fill="#1B6BAD"
          />
          <path
            d="M2562 218C2057.11 68.8209 1288.99 -31.6022 793.781 12.4362C582.546 17.4581 174.345 93.7653 2 218"
            stroke="white"
            stroke-width="4"
          />
        </svg>
      )
    }
  }

  return <span>imagem_curva</span>
}

export default SvgCurve
