import React from 'react'
import { useState, useEffect } from 'react'
import { motion } from 'framer-motion'

import ChevronLink from 'src/components/UI/ChevronLink'

import { useMediaQuery } from 'react-responsive'

import SvgCurve from './SvgCurve'

import img1 from 'src/assets/img/CurveImgs/ImgFlat.webp'
import img2 from 'src/assets/img/CurveImgs/Mobile.webp'

import carouselContent from './slides'

import { Container } from './styles'

const CarouselPage = () => {
  const [current, setCurrent] = useState(0)
  const [selected, setSelected] = useState(carouselContent[current])
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })

  useEffect(() => {
    setSelected(carouselContent[current])
    const next = (current + 1) % carouselContent.length
    const id = setTimeout(() => setCurrent(next), 10000)

    return () => {
      clearTimeout(id)
    }
  }, [current])

  return (
    <Container backgroundImage={isTabletOrMobile ? img2 : img1}>
      <SvgCurve top />

      <motion.div className="info">
        <motion.h1 animate={{ opacity: [0, 1] }}>{selected.title}</motion.h1>
        <motion.h2 animate={{ opacity: [0, 1] }}>{selected.subtitle}</motion.h2>

        <motion.p animate={{ opacity: [0, 1] }}>
          {selected.description}
        </motion.p>

        <ChevronLink color="#fff">Saiba mais</ChevronLink>
      </motion.div>

      <SvgCurve />
    </Container>
  )
}

export default CarouselPage
