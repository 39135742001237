import styled from 'styled-components'

import curvesSm from 'src/assets/img/IconsList/Curve-sm.png'
import curvesM from 'src/assets/img/IconsList/Curve-m.webp'
import curvesL from 'src/assets/img/IconsList/Curve-l.webp'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: -1px auto;
  margin-bottom: 0;
  background-size: cover;
  background-image: url(${curvesSm});
  padding-bottom: 120px;
  z-index: 1;

  @media (min-width: 768px) {
    background-image: url(${curvesM});
  }

  @media (min-width: 1920px) {
    background-image: url(${curvesL});
  }

  h1 {
    color: var(--secondary);
    font-size: 3rem;
    line-height: 45px;
    font-family: 'Gilroy-Bold';
    width: 300px;
    text-align: center;

    @media (min-width: 768px) {
      width: 500px;
    }
  }
`

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  width: 100%;
  max-width: 1200px;
`

export const Item = styled.li`
  display: flex;
  align-items: center;
  transition: 0.4s ease;

  &:not(:last-child) {
    margin-bottom: 50px;
  }

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`

export const Icon = styled.img`
  width: 70px;
  transition: 0.4s ease;

  @media (min-width: 768px) {
    margin-right: 40px;
  }

  @media (max-width: 768px) {
    margin-bottom: 40px;
  }
`

export const Text = styled.p`
  color: #fff;
  max-width: 550px;
  font-size: 20px;
  font-family: 'Gilroy-Regular';
  transition: 0.4s ease;
  cursor: default;

  @media (max-width: 768px) {
    max-width: 350px;
  }
`
