import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 80px 80px;
  padding-left: var(--padding-horizontal);
  background-color: #fff;
  z-index: 5;
  position: relative;
  box-shadow: 0 0 28px rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    padding: 20px 10px;
    align-items: center;
  }
`

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
  }

  h1 {
    font-family: 'Gilroy-Black';
    color: var(--primary);
    line-height: initial;
  }

  h2 {
    letter-spacing: 1px;
    font-size: 30px;
    color: #373435;
    line-height: initial;
  }
`

export const List = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;

  @media (max-width: 1024px) {
    flex-direction: column;
    justify-content: space-around;
  }
`

export const Item = styled.a`
  background-color: #fff;
  min-width: 150px;
  min-height: 150px;
  border-radius: 15px;
  margin: 30px 0;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  transition: 0.3s all ease-out;

  svg {
    fill: #d1d2d4;
  }

  &:hover {
    svg {
      fill: #fff;
    }

    cursor: pointer;
  }

  &.facebook-icon:hover {
    background-color: #0174f0;
  }

  &.linkedin-icon:hover {
    background-color: #37a1dc;
  }

  &.instagram-icon:hover {
    background-color: #dd2a7b;
  }

  &.youtube-icon:hover {
    background-color: #dd1e20;
  }

  &.whatsapp-icon:hover {
    background-color: #25cb63;
  }

  @media (max-width: 768px) {
    width: 80%;

    svg {
      fill: #fff;
    }

    &.facebook-icon {
      background-color: #0174f0;
    }

    &.linkedin-icon {
      background-color: #37a1dc;
    }

    &.instagram-icon {
      background-color: #dd2a7b;
    }

    &.youtube-icon {
      background-color: #dd1e20;
    }

    &.whatsapp-icon {
      background-color: #25cb63;
    }
  }

  @media (max-width: 1024px) {
    width: 65%;
  }
`
