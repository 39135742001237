import React from 'react'

import { Container } from './styles'

const Number = ({ number, text }) => {
  return (
    <Container>
      <span className="number">{number}</span>
      <span className="text">{text}</span>
    </Container>
  )
}

export default Number
