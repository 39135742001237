import React from 'react'

import { Container, ImgContainer } from './styles'

import CityList from './CityList'
import Number from './Number'

import brazil from 'src/assets/img/Local/Brazil.png'

const Locals = () => {
  return (
    <Container>
      <ImgContainer>
        <div className="info">
          <p className="description">
            Nossas soluções estão presentes no cotidiano de vários profissionais
            espalhados por todo o Brasil.
          </p>

          <div className="list">
            <Number number="42" text="Municípios Brasileiros" />

            <Number number="100K" text="Usuários Ativos" />

            <Number number="1,2M" text="Munícipes atingidos" />

            <Number number="47K" text="Horas de experiência" />
          </div>
        </div>

        <img src={brazil} alt="País Brasil" />
      </ImgContainer>

      <CityList />
    </Container>
  )
}

export default Locals
