import icon1 from 'src/assets/img/IconsList/Icon1.png'
import icon2 from 'src/assets/img/IconsList/Icon2.png'
import icon3 from 'src/assets/img/IconsList/Icon3.png'
import icon4 from 'src/assets/img/IconsList/Icon4.png'
import icon5 from 'src/assets/img/IconsList/Icon5.png'

export default [
  {
    icon: icon1,
    text:
      'Com o apoio de profissionais de cada segmento, desenvolvemos softwares especializados em gestão social, com foco em educação, saúde e assistência social.'
  },
  {
    icon: icon2,
    text:
      'Somos especializados em saúde pública, e por isso fornecemos consultoria para gestores municipais com foco em faturamento, utilização de ferramentas ministeriais, legislação, entre outras áreas de conhecimento no segmento de saúde.'
  },
  {
    icon: icon3,
    text:
      'Aperfeiçoamos nossas ferramentas com aplicativos desenvolvidos de forma a serem mais acessíveis a todos os tipos de usuários e dispositivos, garantindo o aumento da abrangência de nossos softwares. '
  },
  {
    icon: icon4,
    text:
      'Acompanhamento e gerenciamento de dados consolidados e extraído de nossas ferramentas, que garante a plena gestão das informações e permite moldar planos de ações mais efetivos.'
  },
  {
    icon: icon5,
    text:
      'Nós da TDR buscamos parcerias que visam construir novos conceitos mercadológicos para soluções aplicáveis a nível nacional ou internacional.'
  }
]
