import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 790px) {
    align-items: center;
    margin: 10px 20px;
  }

  .number,
  .text {
    color: #fff;
  }

  .number {
    font-size: 65px;
    font-family: 'Gilroy-Bold';
  }

  .text {
    font-size: 22px;
  }

  &:not(:last-child) {
    margin-bottom: 25px;
  }
`
