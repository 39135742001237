import styled from 'styled-components'
import BG from 'src/assets/img/CTABG.webp'

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`

export const Background = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 300px;
  padding: 120px 60px;
  padding-left: var(--padding-horizontal);

  background-image: url(${BG});
  background-size: cover;

  @media (max-width: 960px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h1 {
    color: #fff;
    font-family: 'Gilroy-Bold';
    font-size: 50px;
    text-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
    max-width: 700px;
    line-height: 50px;
  }

  h2 {
    color: #fff;
    font-family: 'Gilroy-Thin';
    text-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
    max-width: 600px;
    font-size: 16px;
    margin-top: 30px;
    line-height: initial;

    @media (min-width: 960px) {
      text-align: start;
      align-self: flex-start;
    }

    @media (max-width: 960px) {
      margin-bottom: 30px;
    }
  }

  @media (min-width: 960px) {
    text-align: start;
    align-self: flex-start;
  }
`

export const Button = styled.a`
  background-color: var(--primary);
  padding: 1.5rem 2rem;
  border-radius: 9px;
  color: #fff;
  font-size: 25px;
  font-family: 'Gilroy-Bold';
  cursor: pointer;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.45);
  transition: 0.3s all ease;
  text-align: center;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.55);
  }
`
