import React from 'react'

import { Container, Background, Text, Button } from './styles'

const Resale = () => {
  return (
    <Container id="resale-section">
      <Background>
        <Text>
          <h1>Conheça nosso plano de revenda comercial</h1>
          <h2>
            Seja um parceiro comercial da TDR e garanta a qualidade e
            experiência necessária para seu cliente. Conheça agora nossas
            condições especiais para parceiros e revendas.
          </h2>
        </Text>

        <Button href="#">Fale com um consultor</Button>
      </Background>
    </Container>
  )
}

export default Resale
