import React from 'react'
import {
  FaFacebookSquare,
  FaLinkedin,
  FaYoutube,
  FaWhatsappSquare
} from 'react-icons/fa'
import { RiInstagramFill } from 'react-icons/ri'

import {
  FACEBOOK,
  LINKEDIN,
  INSTAGRAM,
  YOUTUBE,
  WHATSAPP
} from 'src/config/socialMediaLinks'

import { Container, Text, List, Item } from './styles'

const SocialMedia = () => {
  return (
    <Container>
      <Text>
        <h1>Conheça</h1>
        <h2>nossas redes sociais</h2>
      </Text>

      <List>
        <Item href={FACEBOOK} target="_blank" className="facebook-icon">
          <FaFacebookSquare size={90} />
        </Item>

        <Item href={LINKEDIN} target="_blank" className="linkedin-icon">
          <FaLinkedin size={90} />
        </Item>

        <Item href={INSTAGRAM} target="_blank" className="instagram-icon">
          <RiInstagramFill size={90} />
        </Item>

        <Item href={YOUTUBE} target="_blank" className="youtube-icon">
          <FaYoutube size={90} />
        </Item>

        <Item href={WHATSAPP} target="_blank" className="whatsapp-icon">
          <FaWhatsappSquare size={90} />
        </Item>
      </List>
    </Container>
  )
}

export default SocialMedia
