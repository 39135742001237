import React from 'react'

import Hero from 'src/components/Home/Sections/Hero'
import CarouselPage from 'src/components/Home/Sections/CarouselPage'
import WhatWeDo from 'src/components/Home/Sections/WhatWeDo'
import Resale from 'src/components/Home/Sections/Resale'
import Locals from 'src/components/Home/Sections/Locals'
import SocialMedia from 'src/components/Home/Sections/SocialMedia'

import { Container } from 'src/styles/home'

export default function Home() {
  return (
    <Container>
      <Hero
        title="Soluções inteligentes"
        subtitle="para desafios diários"
        text="Nosso objetivo é compreender comportamentos individuais e
            proporcionar soluções inteligentes e dinâmicas"
        showIcons
      />

      <CarouselPage />

      <WhatWeDo />

      <Resale />

      <Locals />

      <SocialMedia />
    </Container>
  )
}
