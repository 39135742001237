export default [
  {
    title: 'Sistema integrado',
    subtitle: 'para uma gestão completa',
    description:
      'Somos especialistas em gestão de saúde pública e privada. Com soluções completas e totalmente integradas, vinculando todas as especialidades e setores. Conheça mais sobre nossas soluções.'
  },
  {
    title: 'Soluções completas',
    subtitle: 'para educação pública e privada',
    description:
      'Nossa experiência na área da educação foi moldada pela ótica dos profissionais que utilizam nossas ferramentas em suas tarefas diárias. Com essa parceria criamos soluções mais completas e abrangentes.'
  },
  {
    title: 'Promovendo a integração',
    subtitle: 'de gestores sociais municipais',
    description:
      'Nossas soluções foram desenvolvidas a fim de promover a integração total de serviços voltados a população. Um processo chamado gestão social permite o compartilhamento de informações em tempo real e a integração total dos dados de munícipes.'
  }
]
